
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid';
import SelectMenu from '@/components/SelectMenu.vue';
import ICategory from '@/models/interfaces/ICategory';
import Category from '@/models/Category';
import AreYouSureModal from '@/components/modals/AreYouSureModal.vue';

@Options({
  components: {
    AreYouSureModal,
    SelectMenu,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LinkIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    XIcon,
  },
  props: {
    open: Boolean,
    editingItem: Category,
    slug: String,
    year: String,
  },
  emits: [
    'closed',
  ],
})
export default class AddEditCategorySlideOver extends Vue {
  open!: boolean;

  slug!: string;

  year!: string;

  areYouSureDeleteOpen = false;

  close(refresh = true): void {
    this.$emit('closed', true);
  }

  get selectedName() {
    console.log(this.$store.state);
    return this.$store.state.editingCategory?.name;
  }

  set selectedName(val: string) {
    this.$store.dispatch('setEditCategoryName', val);
  }

  // eslint-disable-next-line class-methods-use-this
  get selectedDescription() {
    return this.$store.state.editingCategory?.description;
  }

  // eslint-disable-next-line class-methods-use-this
  set selectedDescription(val: string) {
    this.$store.dispatch('setEditCategoryDescription', val);
  }

  get isEditing() {
    return !!this.$store.state.editingCategory?.id;
  }

  get isNovice() {
    return this.$store.state.editingCategory?.novice;
  }

  set isNovice(val: boolean) {
    this.$store.dispatch('setEditCategoryNovice', val);
  }

  save(): void {
    console.log(this.$store.state.editingCategory);
    if (this.$store.state.editingCategory?.id) {
      this.axios.post(`${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}/categories`, {
        name: this.selectedName,
        description: this.selectedDescription,
        id: this.$store.state.editingCategory?.id,
        novice: this.isNovice,
      })
        .then(() => this.close(true));
    } else {
      this.axios.put(`${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}/categories`, {
        name: this.selectedName,
        description: this.selectedDescription,
        novice: this.isNovice,
      })
        .then(() => this.close(true));
    }
  }

  deleteItem(): void {
    this.areYouSureDeleteOpen = true;
  }

  // eslint-disable-next-line class-methods-use-this
  confirmDelete(): void {
    console.log('dletgin');
    this.axios.delete(`${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}/categories/${this.$store.state.editingCategory?.id}`)
      .then(() => {
        this.areYouSureDeleteOpen = false;
        this.close(true);
      });
  }
}
