import ICategory from '@/models/interfaces/ICategory';
import Placing from '@/models/Placings';

export default class Category implements ICategory {
  name: string;

  description: string;

  id: number | undefined;

  placings: Placing;

  novice = false;

  constructor(name: string, description: string, id: number | undefined, placings: Placing, novice: boolean) {
    this.name = name;
    this.description = description;
    this.id = id;
    this.placings = placings;
    this.novice = novice;
  }
}
